import { createRouter,createWebHistory } from 'vue-router'

export default createRouter({
    routes:[
        {
            path:'/',
            component:() => import('@/pages/index.vue'),
            name:'index',
            meta:{
                title:'nftnet'
            }
        }
    ],
    history:createWebHistory(),
});