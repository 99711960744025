<template>
  <div class="main-wrapper">
    <!-- 顶部导航 -->
    <div class="top-nav">
      <img src="@/assets/nav/logo.png" alt="" class="logo">
      <div class="links">
        <!-- <a href="" class="link-item">
          <img src="@/assets/nav/icon-boat.png" alt="">
        </a> -->
        <a href="https://twitter.com/nftnet001" target="_blank" class="link-item">
          <img src="@/assets/nav/icon-twitter.png" alt="twitter">
        </a>
        <!-- <a href="" class="link-item">
          <img src="@/assets/nav/icon-discord.png" alt="discord">
        </a> -->
        <a href="" class="link-item">
          <img src="@/assets/nav/icon-language.png" alt="language">
        </a>
      </div>
    </div>

    <router-view></router-view>

    <!-- footer -->
    <div class="footer">
      <div class="footer-wrapper">
        <div class="links" v-if="false">
          <div class="link-item">Privacy policy</div>
          <div class="link-item">Terms of service</div>
          <div class="link-item">Guide for Specified Commercial Transactions Act</div>
          <div class="link-item">Operating company</div>
        </div>
        <div class="copyright">©️ All rights reserved by NFTNET Co., Ltd</div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>


<style lang="scss" scoped>
.main-wrapper{
  background-color: #111;
  // 顶部导航
  .top-nav{
    height: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo{
      width:302px;
      margin-left: 140px;
    }
    .links{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 300px;
      .link-item{
        margin-left: 97px;
        cursor: pointer;
        & > img{
          width:62px;
        }
      }
    }
  }
  // 底部footer
  .footer{
    padding-top: 80px;
    padding-bottom: 290px;
    border-top: 1px solid #fff;
    .footer-wrapper{
      width:1140px;
      margin:0 auto;
      .links{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 53px;
        .link-item{
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
      .copyright{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #999999;
        text-align: center;
      }
    }
  }
}
</style>
